/* .form-check label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #004c97;
    background-color: transparent;
  }
  
  .form-check input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #0F838C;
  } */

#tacticEvaluation{
  font-size: 14px;
  border-radius: 6px;
  color: #4B5768;
  min-height: 30px;
  display: contents;
}

#tacticEvaluation label input{
  float: left;
}

#tacticEvaluation:hover {
  background-color:#05727B;
  /* overflow:hidden; */
  color:white;
}

#tacticEvaluation label input {
  position:absolute;
  visibility: hidden;
}

#tacticEvaluation label span {
  text-align:left;
  display:block;
  word-wrap: break-word;
  border-radius: 6px;
  min-height: 37px;
  padding: 8px;
}

#tacticEvaluation label{
  display:contents;
  border-radius: 6px;
  min-height: 37px;
  cursor: pointer;
}

#tacticEvaluation label:hover{
  background-color: #0F838C;
  /* overflow:hidden; */
  color:white;
}
#tacticEvaluation label span:hover{
  background-color: #0F838C;
  /* overflow:hidden; */
  color:white;
}

#tacticEvaluation input:checked + span {
  background-color:#0F838C;
  color:#fff;
}
.modal-open{
  overflow: hidden;
}