/* .ck-sticky-panel{
    width: 100%;
    height: 20px;
} */
/* .--container .ck-editor__editable {
    max-height: 850px;
} */

.--key-takeaways .ck-editor__editable{
    /* max-height: 820px; */
    max-height: 830px;
    width: 100%;
}

.--content-quick-wins .ck-editor__editable{
    /* max-height: 750px; */
    max-height: 780px;
    width: 100%;
}

.--doing-well .ck-editor__editable{
    max-height: 780px;
    width: 100%;
}

.--key-observations .ck-editor__editable{
    max-height: 780px;
    width: 100%;
}

.--executive-summary .ck-editor__editable{
    max-height: 830px;
    width: 100%;
}

.paf-container{
    height: 260px;
    overflow: hidden;
}
.paf-container .ck-content {
    height: 180px;

}

/* For Priority Areas Of Focus */
.paf-container .ck-content p, 
.paf-container .ck-content h4, 
.paf-container .ck-content h3, 
.paf-container .ck-content h2 {
    color: #0D0F11
}
.--PAF-Card .SavedPAFCard p, 
.--PAF-Card .SavedPAFCard h4, 
.--PAF-Card .SavedPAFCard h3, 
.--PAF-Card .SavedPAFCard h2 {
    color: #0D0F11;
}


/* For all pragraph, order-list, unorder-list */
.--container .ck-content p,
.--container .ck-content ul li,
.--container .ck-content ol li {
    font-size: 14px;
    margin-top: 8px;
    color: #0D0F11;
}


/* For all Ck Editor Headings in Edit mode*/
.--container .ck-content h1,
.--container .ck-content h2,
.--container .ck-content h3,
.--container .ck-content h4 {
    margin-bottom: 8px;
    color: #0D0F11;
}

.ImageUploadContainer:hover .container--toggle {
    display: block !important;
}


/* For Channel First page */
.--channel-first-page .SavedDescription ul li,
.--channel-first-page .SavedDescription ol li{
    margin-top: 8px;
}

.--channel-first-page .SavedDescription h1,
.--channel-first-page .SavedDescription h2,
.--channel-first-page .SavedDescription h3,
.--channel-first-page .SavedDescription h4 {
    color: #0D0F11;
    margin-top: 8px;
}


/* For Executive Summary page */
.--executive-summary .SavedExecutiveSummary ul li,
.--executive-summary .SavedExecutiveSummary ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--executive-summary .SavedExecutiveSummary h1,
.--executive-summary .SavedExecutiveSummary h2,
.--executive-summary .SavedExecutiveSummary h3,
.--executive-summary .SavedExecutiveSummary h4 {
    margin-top: 8px;
    color: #0D0F11;
}


/* For Key Obsevation page */
.--key-observations .SavedKeyObservations ul li,
.--key-observations .SavedKeyObservations ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--key-observations .SavedKeyObservations h1,
.--key-observations .SavedKeyObservations h2,
.--key-observations .SavedKeyObservations h3,
.--key-observations .SavedKeyObservations h4 {
    margin-top: 8px;
    color: #0D0F11    
}


/* For What You Are Doing Well page */
.--doing-well .SavedDoingWell ul li,
.--doing-well .SavedDoingWell ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--doing-well .SavedDoingWell h1,
.--doing-well .SavedDoingWell h2,
.--doing-well .SavedDoingWell h3,
.--doing-well .SavedDoingWell h4 {
    margin-top: 8px;
    color: #0D0F11    
}


/* For Content Quick Wins page */
.--content-quick-wins .SavedContentQuickWins ul li,
.--content-quick-wins .SavedContentQuickWins ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--content-quick-wins .SavedContentQuickWins h1,
.--content-quick-wins .SavedContentQuickWins h2,
.--content-quick-wins .SavedContentQuickWins h3,
.--content-quick-wins .SavedContentQuickWins h4 {
    color: #0D0F11;
    margin-top: 8px;
}


/* For Key Takeawys page */
.--key-takeaways .SavedKeyTakeaways ul li,
.--key-takeaways .SavedKeyTakeaways ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--key-takeaways .SavedKeyTakeaways h1,
.--key-takeaways .SavedKeyTakeaways h2,
.--key-takeaways .SavedKeyTakeaways h3,
.--key-takeaways .SavedKeyTakeaways h4 {
    color: #0D0F11;
    margin-top: 8px;
}


/* .ImageUploadContainer__ {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
} */

.container--toggle {
    display: none !important;

}

.ContainerAnimation--FadeIn {
    animation-name: FADE__IN;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

@keyframes FADE__IN {
    0% {
        opacity: 0;
    }

    /* 50%{
        opacity: .5;
    } */
    100% {
        opacity: 1;
    }
}