/* --------------- Scorecard List CSS -------------- */
.scorecards-black-card {
  display: flex;
  flex-direction: column;
  background-color: black;
  height: 330px;
}

.scorecards-black-card h2 {
  display: flex;
  justify-content: center;
  color: white;
  transform: rotate(-90deg);
  margin-top: 140px;
  font-size: 30px;
  letter-spacing: 2px;
}

.custom-scorecard-table thead tr th {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  color: black;
  font-weight: 700;
}

.custom-scorecard-table tbody tr td {
  outline: none;
  border: none;
}

.custom-scorecard-table tbody tr td {
  height: 72px !important;
  color: black;
  font-size: 15px;
}

.Rules-Container {
  overflow-x: auto;
  height: 330px;
}

.Rules-Container .tableHeader {
  position: sticky;
  background-color: #ffffff;
  top: 0;
  /* border-bottom: 2px solid rgb(153, 163, 164) !important; */
}

.table-responsive.Rules-Container .tableHeader::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 2px solid rgb(153, 163, 164) !important;
  /* Border color */
}

.Rules-Container .custom-scorecard-table tbody {
  overflow-y: scroll;
  max-height: 300px;
  /* Adjust max-height as needed */
}

/* Scrollbar css for scorecard table */
.Rules-Container::-webkit-scrollbar {
  width: 9px;
  background: #BCBCBC;
  border-radius: 10px;
}

.Rules-Container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.Rules-Container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #393939;
}


/* View Card Button in Scorecard List */
.view-card-btn {
  border: none;
  /* background-color: #FEF3C7; */
  background-color: #CB6928;
  color: #fff;
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  width: 150px;
  font-size: 13px !important;
  height: 30px;
}

.view-card-btn:hover {
  background-color: black;
  color: #ffffff;
}

/* View All Scorecard Button in Scorecard List */
.view-all-scorecard-btn {
  border: none;
  background-color: #e7eaee;
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  width: 150px;
  height: 30px;
  color: #64748b;
}

.view-all-scorecard-btn:hover {
  background-color: #003f44;
  color: #ffffff;
}






/* -------------- Users List CSS -------------- */
.users-black-card {
  display: flex;
  flex-direction: column;
  background-color: black;
  height: 330px;
}

.users-black-card h2 {
  display: flex;
  justify-content: center;
  color: white;
  transform: rotate(-90deg);
  margin-top: 140px;
  font-size: 30px;
  letter-spacing: 2px;
}

.custom-users-table thead tr th {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  color: black;
  font-weight: 700;
}

.custom-users-table tbody tr td {
  outline: none;
  border: none;
}

.custom-users-table tbody tr td {
  height: 60px !important;
  font-size: 15px;
}

.Rules-Container-Users {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 330px;
}

.Rules-Container-Users .tableHeaderUser {
  position: sticky;
  background-color: #ffffff;
  top: 0;
  /* border-bottom: 2px solid rgb(153, 163, 164) !important; */
}

.table-responsive.Rules-Container-Users .tableHeaderUser::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 2px solid rgb(153, 163, 164) !important;
  /* Border color */
}

.Rules-Container-Users .custom-users-table tbody {
  overflow-y: scroll;
  max-height: 300px;
  /* Adjust max-height as needed */
}


/* View User Button in Users List */
.view-user-btn {
  border: none;
  /* background-color: #FEF3C7; */
  color: #fff;
  background-color: #CB6928;
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  width: 150px;
  font-size: 13px !important;
  height: 30px;
}

.view-user-btn:hover {
  background-color: black;
  color: #ffffff;
}

/* View All Users Button in Users List */
.view-all-users-btn {
  border: none;
  background-color: #e7eaee;
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  width: 150px;
  height: 30px;
  color: #64748b;
}

.view-all-users-btn:hover {
  background-color: #003f44;
  color: #ffffff;
}







/* Media Query's for black cards */

@media screen and (max-width: 768px) and (max-height: 768px) {

  /* For Scorecard List Card */
  .scorecards-black-card {
    display: flex;
    flex-direction: row;
    height: max-content;
    text-align: center;
    padding: 10px;
  }

  .scorecards-black-card h2 {
    height: max-content;
    text-align: center;
    justify-content: center;
    transform: none;
    margin: 1px auto 1px auto;
  }

  /* For Users List Card */
  .users-black-card {
    display: flex;
    flex-direction: row;
    height: max-content;
    text-align: center;
    padding: 10px;
  }

  .users-black-card h2 {
    height: max-content;
    text-align: center;
    justify-content: center;
    transform: none;
    margin: 1px auto 1px auto;
  }
}


/* Add Credit Button CSS */
.add-credit-btn {
  border: none;
  /* background-color: #FEF3C7; */
  background-color: #CB6928;
  color: #fff;
  border-radius: 6px;
  outline: none;
  font-weight: 400;
  width: 70%;
  font-size: 13px !important;
  height: 30px;
}


/* Style the tooltip */
.userlist-tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.userlist-tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  white-space: nowrap;
  background-color: #fff;
  color: black;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 7px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.userlist-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}