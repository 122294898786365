/* .ck-sticky-panel{
    width: 100%;
    height: 20px;
} */
.--container .ck-editor__editable {
    height: 870px;

}

.PAF--container .ck-editor__editable {
    height: 140px;

}

.--container .ck-content p,
.--container .ck-content ul li {
    font-size: 14px;
    margin-top: 8px;
}

.ImageUploadContainer:hover .container--toggle {
    display: block !important;
}

.--executive-summary .SavedExecutiveSummary ul li{
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: #292929;
}
.--key-observations .SavedKeyObservations ul li{
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: #292929;
}
.--doing-well .SavedDoingWell ul li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: #292929;
}
.--content-quick-wins .SavedContentQuickWins ul li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: #292929;
}
.--key-takeaways .SavedKeyTakeaways ul li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
    color: #292929;
}

/* .ImageUploadContainer__ {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
} */

.container--toggle {
    display: none !important;

}

.ContainerAnimation--FadeIn {
    animation-name: FADE__IN;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

@keyframes FADE__IN {
    0% {
        opacity: 0;
    }

    /* 50%{
        opacity: .5;
    } */
    100% {
        opacity: 1;
    }
}