/* View Card Button in Account Users Scorecard List */
.au-view-card-btn {
    border: none;
    /* background-color: #FEF3C7; */
    background-color: #CB6928;
    color: #ffffff;
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    width: 150px;
    font-size: 13px !important;
    height: 30px;
}

.au-view-card-btn:hover {
    background-color: black;
    color: #ffffff;
}

.au-closed-btn-css {
    border: none;
    background-color: #8C8C8C;
    color: #ffffff;
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    width: 150px;
    font-size: 13px !important;
    height: 30px;
    cursor: initial !important;
}