.customScrollBar::-webkit-scrollbar{
    width: 12px;
    background: #BCBCBC;
    border-radius: 10px;
}
.customScrollBar::-webkit-scrollbar-track{
    border-radius: 10px;
}
.customScrollBar::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: #393939;
}

#customCheckBox input:checked {
    border-radius: 2px;
    background-color: #05727B;
}