#parentdiv {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 150px;
  margin: 200px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-right:200px;
}

.div3 {
  position: absolute;
  width: 80px;
  height: 80px;
  /* background-color: #6BB9B7; */
  border-radius: 100px;
  color: #fff;
  text-align: center;
  line-height: 120px;
  /* border: 1px solid black; */
  /* overflow: hidden; */
}
/* .div2 span {
  position: relative;
  width: 90px;
  height: 95px;
  font-size: 10px;
  margin-top: 0px;
  padding-top: 25px;
  justify-content: center;
  align-items:center; */

  /* --------------------- */
  /* display: block; */
  /* line-height: 20px;   */
  /* margin-left: 3px; */
  /* padding-right: 20px; */
  /* border: 1px solid black; */
  /* text-align: center; */
/* } */