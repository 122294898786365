Select.select-error {
    border-color: brown;

}

.message {
    display: flex;
    color: rgb(230, 20, 20);
    font-size: 12px;
    justify-content: center;
    margin-top: -12px;
}


/* CSS For Channel selection checkbox and button */
#selectChannelCheckbox input:checked {
    background-color: #05727B;
    color: #fff;
}

.assigneeMessage {
    color: #f08080;
}

