.spider-graph-menu:hover {
    background-color:#05727B;
    overflow:hidden;
    color:white;
    border: #05727B;
    border-radius: 10px;
}

.spider-graph-menu li{
    /* text-align:center;  */
    list-style-type:none; 
    font-size:15px;
    cursor:pointer;
}

.tour-guide-menu li {
    list-style-type:none; 
    font-size:15px;
    cursor:pointer;
}

.coach-page-btn {
    color: white;
    border: #0D0F11;
    background-color: #0D0F11;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
}