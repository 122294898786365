#parentdiv {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 150px;
  margin: 200px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-right:200px;
}

.div2 {
  position: relative;
  width: 115px;
  height: 115px;
  background-color: #6BB9B7;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  line-height: 120px;
  /* border: 1px solid black; */
}
.div2 span {
  position: absolute;
  width: 100px;
  height: auto;
  display: block;
  font-size: 14px;
  line-height: 20px;  
  margin-top: 15px;
  margin-left: 3px;
  padding-top: 18px;
  /* padding-right: 20px; */
  /* border: 1px solid black; */
  justify-content: center;
  align-items:center;
  /* text-align: center; */
  font-family: "Roboto", sans-serif;
}

/* .text-top span {
  padding-top: 30px;
}

.span-center span{
  margin-top: -20px;
} */
  /* border: 1px solid black; */