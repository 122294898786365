.view-card-btn-for-all-scorecard-list {
    border: none;
    /* background-color: #FEF3C7; */
    background-color: #CB6928;
    color: #ffffff;
    border-radius: 6px;
    outline: none;
    font-weight: 400;
    width: 150px;
    font-size: 13px !important;
    height: 30px;
}

.view-card-btn-for-all-scorecard-list:hover {
    background-color: black;
    color: #ffffff;
}