/* .ck-sticky-panel{
    width: 100%;
    height: 20px;
} */
.--container .ck-editor__editable {
    height: 870px;

}

.--PAF-Card .SavedPAFCard p, 
.--PAF-Card .SavedPAFCard h4, 
.--PAF-Card .SavedPAFCard h3, 
.--PAF-Card .SavedPAFCard h2 {
    color: #292929;
}

.--container .ck-content p,
.--container .ck-content ul li{
    font-size: 14px;
    margin: 4px auto;
}

.ImageUploadContainer:hover .container--toggle {
    display: block !important;
}

.--channel-first-page .SavedDescription ul li,
.--channel-first-page .SavedDescription ol li{
    margin-top: 8px;
}

.--executive-summary .SavedExecutiveSummary ul li,
.--executive-summary .SavedExecutiveSummary ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--key-observations .SavedKeyObservations ul li,
.--key-observations .SavedKeyObservations ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--doing-well .SavedDoingWell ul li,
.--doing-well .SavedDoingWell ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--content-quick-wins .SavedContentQuickWins ul li,
.--content-quick-wins .SavedContentQuickWins ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

.--key-takeaways .SavedKeyTakeaways ul li,
.--key-takeaways .SavedKeyTakeaways ol li {
    margin-top: 8px;
    margin-left: 20px;
    font-size: 14px;
}

/* .ImageUploadContainer__ {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .3);
} */

.container--toggle {
    display: none !important;

}

.ContainerAnimation--FadeIn {
    animation-name: FADE__IN;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

@keyframes FADE__IN {
    0% {
        opacity: 0;
    }

    /* 50%{
        opacity: .5;
    } */
    100% {
        opacity: 1;
    }
}