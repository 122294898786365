#parentdiv {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 150px;
  margin: 200px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-right:200px;
}

.div2 {
  position: relative;
  /* width: 115px;
  height: 115px; */
  background-color: #6BB9B7;
  border-radius: 100px;
  color: #fff;
  text-align: center;
  line-height: 120px;
  /* border: 1px solid black; */
  /* overflow: hidden; */
}
.div2 span {
  position: absolute;
  width: 100px;
  height: auto;
  writing-mode: horizontal-tb;
  top: 30%;
  transform: translateY(-50%);
  font-size: 14px;
  font-family: 'Roboto , sans-serif';
  /* height: 90px;  */
  /* border-radius: 50%; */
  /* display: inline-block; */
  /* *display: inline;
  vertical-align: middle; */
  /* font-size: 14px; */
  /* line-height: 20px;   */
  /* margin-top: -2px; */
  margin-left: 9px;
  /* padding-top: 25px; */
  /* padding-right: 20px; */
  /* border: 1px solid black; */
  /* justify-content: center;
  align-items:center; */
  text-align: center;
}

/* .center-top span{
  padding-top: 32px;
  padding-bottom: 10px;
}
.center-text span{
 
  margin-top: -18px;
  
}
.center-text1 span{
  margin-top: 0px;
} */